.asset {
  margin: 16px 24px;
}

.inner {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 100px;
}

.nav {
  display: flex;
}

.back {
  display: flex;
  align-items: center;

  &:hover,
  &:focus {
    color: var(--color-highlight);
  }

  &Text {
    margin-left: 8px;
  }
}

.nextBtn {
  padding-left: 10px;
  justify-content: left;
  background-image: url('/src/assets/icons/arrow-right.svg');
  background-position: 90% 50%;
  &:hover {
    background-color: #000;
    color: #fff;
    background-image: url('/src/assets/icons/arrow-right-hover.svg');
    background-position: 88% 50%;
    background-repeat: no-repeat;
  }
  &:disabled {
    color: #fff;
    background-color: #e5e5e5;
    background-image: url('/src/assets/icons/arrow-right-hover.svg');
    border: solid 2px #e5e5e5;
  }
}
.prevBtn {
  padding-right: 10px;
  text-align: right;
  background-image: url('/src/assets/icons/arrow-left.svg');
  background-position: 10% 50%;
  &:hover {
    background-color: #000;
    color: #fff;
    background-image: url('/src/assets/icons/arrow-left-hover.svg');
    background-position: 10% 50%;
    background-repeat: no-repeat;
  }
  &:disabled {
    color: #fff;
    background-color: #e5e5e5;
    background-image: url('/src/assets/icons/arrow-left-hover.svg');
    border: solid 2px #e5e5e5;
  }
}
.button {
  width: 91px;
  height: 30px;
  font-size: 18px;
  border-radius: 15px;
  color: #000;
  background-color: #fff;
  border: solid 2px #000;
}

.content {
  display: flex;
  margin-top: 30px;
}

.card {
  flex: none;
}
.right {
  &View {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
  }
}

.info {
  &HeaderMobile {
    display: none;
  }

  &Desc {
    font-size: 18px;
    font-weight: 600;
  }
}

.cancel {
  &ListingLarge {
    width: 212px !important;
  }
  &Listing {
    height: 40px;
    width: 96px;
    padding: 0px;
    border-radius: 15px;
    font-size: 18px;
    border: 2px solid #000;
    color: #000 !important;
    background-color: #fff !important;
    &:hover {
      border: 2px solid #000;
      color: #fff !important;
      background-color: #000 !important;
    }
  }
}
.checkout {
  width: 205px;
}
.trade {
  height: 40px;
  width: 212px;
  padding: 0px;
  border-radius: 15px;
  margin-bottom: 10px;
  font-size: 18px;
  border: 2px solid #ff6c2d;
  color: #fff !important;
  background-color: #ff6c2d !important;
  &:hover {
    border: 2px solid #ff6c2d;
    color: #fff !important;
    background-color: #ff6c2d !important;
  }
  &:focus {
  }
  &View {
    display: inline-flex;
    align-items: center;
    margin-top: 33px;
    flex-direction: column;
    width: 100%;
  }
}

.activity {
  display: block;
}
.history {
  width: 448px;
}
.buyCard img {
  margin-bottom: 10px;
}

.cancel {
  display: block;

  &Content {
    margin: 24px 0;
    border-radius: 4px;
  }

  &Footer {
    display: flex;
    justify-content: space-between;
  }

  &Button {
    font-size: 14px;
    margin: 0 16px;
    padding: 8px 16px;
  }
}

.openOutside {
  display: flex;
}
.openOutside svg {
  fill: none;
}
.externalLink {
  &:hover {
    color: #ff6c2d;
  }
}

@media (--view-md) {
  .content {
    flex-direction: column;
  }

  .card {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 8px;
  }

  .info {
    &HeaderMobile {
      display: flex;
    }
  }
}
