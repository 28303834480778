
.table {
  display: flex;
  flex-direction: column;
  width: 100%;
  &Wrapper {
    width: 100%;
    padding: 1px 0px;
  }
  &Header {
    display: flex;
    &Row {
       display: flex;
       height: 36px;
       flex: 1;
       padding: 0 10px;
       align-items: center;
       background-color: #eeeeee;
       border-radius: 8px;
    }
    &Cell {
      font-size: 12px;
      font-weight: 600;
      color: #909090;
      display: flex;
      align-items: center;
      vertical-align: middle;
    }
    &Cell:not(:last-child) {
       padding-right: 10px;
    }
  }
  &Body {
    display: flex;
    flex-direction: column;
    position: relative;
    &Row {
      position: relative;
      display: flex;
      height: 64px;
      margin: 0 10px;
      border-bottom: 1px solid #EEEEEE;
      &Split {
        border-bottom: 1px solid #EEEEEE;
        margin-left: 10px;
      }
    }
    &Cell {
      font-size: 12px;
      display: flex;
      align-items: center;
      vertical-align: middle;
      width: 71px;
      /*border-bottom: solid 1px #000;*/
      a {
        text-decoration: underline;
        &:hover {
          color: #000000;
        }
      }
    }
    &Cell:not(:last-child) {
      padding-right: 10px;
    }
  }
}
