.list {
  width: 100%;
  max-width: 1432px;
  flex: 1;
  margin: 0 auto;

  &Header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    &SearchInput {
      width: 108px;

      &Large {
        width: 212px;
      }
    }
  }
}

.check {
  display: flex;
  align-items: center;
  margin-left: 5px;
  &Title {
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
  }

  &SelectAll {
    border: 1px solid #000000;
    border-radius: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: #000000;
    padding: 2.5px 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    cursor: pointer;
    &Disabled {
      color: #909090;
      border: 1px solid #000000;
    }
    &Selected {
      border: 1px solid #FF6C2D;
      background: #FF6C2D;
      color: #FFFFFF;
    }
  }

  &ClearAll {
    border: 1px solid #000000;
    border-radius: 10px;
    font-weight: 400;
    font-size: 14px;
    padding: 2.5px 7px;
    display: flex;
    align-items: center;
    line-height: 15px;
    justify-content: center;
    color: #909090;
    cursor: pointer;

    &Active {
      color: #000000;
    }
  }
}

.sum {
  display: flex;
  font-family: Archivo;
  font-size: 20px;
}

.sort {
  display: flex;
}

.select:first-child {
  margin: 0 16px;
}

.select select {
  width: 212px;
  border: 0;
  border-bottom: 1px solid;
}

.cards {
  display: flex;
  justify-content: center;
  margin: 20px auto;
  flex-wrap: wrap;
  gap: 24px;

  &Height {
    &LargeModal {
      height: 380px;
      overflow: auto;
    }

    &SmallModal {
      height: 390px;
      overflow: auto;
    }
  }

  &Small {
    margin: 0 auto !important;
    margin-top: 16px !important;
    gap: 4px 15px !important;
  }

  &NotFound {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 380px;
    padding-top: 84px;

    &Large {
      min-height: 516px;
    }

    span {
      margin-top: 36px;
      font-weight: 400;
      font-size: 20px;
      line-height: 22px;
      color: #000000;
    }

    svg {
      width: 72px;
      height: 72px;
      fill: none;
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;
  padding: 40px 0;
}

.marketplace .menu {
  display: none;
}

.card {
  cursor: inherit;
  position: relative;
  display: inline-flex;
  width: 212px;
  flex-direction: column;
  justify-content: center;
  height: 262px;
  background: #F6F8FD;
  border-radius: 8px;

  &Pointer {
    cursor: pointer;
  }

  &Pagination {
    padding-top: 10px;
    padding-bottom: 30px;
  }

  &BreedInfo {
    font-size: 12px;
    color: #909090;
  }

  &Item {
    a:hover {
      color: #000000;
    }
  }

  &Info {
    display: flex;
    margin-top: 12px;
    font-size: 12px;
    align-items: flex-start;
    flex-direction: column;
    min-height: 50px;

    span:only-child {
      margin: 0;
    }
  }

  &Id {
    display: flex;
    align-items: center;
    margin-right: auto;
    padding: 3px 6px;
    padding-left: 0;
    border-radius: 2px;
    font-size: 14px;
    line-height: 14px;

    svg {
      width: 14px;
      height: 14px;
      margin-right: 4px;
    }
  }

  &Link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }
}

@media (min-width: 1600px) {
  .list {
    width: 100%;
    max-width: 100%;
    flex: 1;
    margin: 0 auto;
  }
}

@media (max-width: 968px) {
  .card {
    width: 152px;
    height: 188px;
    &Info {
       width: 152px;
    }
  }
  .cards {
    display: flex;
    justify-content: center;
    margin: 11px auto;
    flex-wrap: wrap;
    gap: 16px;
    height: 321px;
    overflow-y: scroll;
    &Small {
       height: 380px;
       overflow: auto;
    }
  }
}
@media (max-width: 851px) {
  .open {
    display: flex;
    flex-direction: column;
    padding-right: 0px;
  }

  .menu {
    display: flex !important;
    width: 32px;
    height: 32px;
    -webkit-user-select: none;
    user-select: none;
    z-index: var(--z-index-header);
    align-items: center;
    justify-content: flex-end;
  }

  .menu svg {
    width: 28px;
    height: 28px;
  }

  .filter {
    &Row {
      display: none;
      flex-direction: column;
      padding-right: 0px;
    }

    &Block {
      margin-right: 0px;
      margin-bottom: 15px;
    }

    &Block:first-child {
      margin-bottom: 0px;
    }
  }

  .sort {
    justify-content: space-between;
  }

  .inner {
    margin: 16px;
  }

  .split {
    margin-top: 15px;
  }

  .list {
    &Header {
      flex-direction: column;
      margin-top: 25px;

      &Small {
        flex-direction: row;
        margin-top: 0px;
      }
    }
  }

  .pagination {
    padding-top: 0px;
    margin-top: 28px !important;
    padding-bottom: 0px;
  }

  .select select {
    width: 100%;
  }

  .select:first-child {
    margin: 0px;
  }

  .select {
    margin: 0px;
    width: 47%;
  }
}

@media (max-width: 720px) {
  .list {
    &Header {
      &Small {
        flex-direction: column;
      }

      &SearchInput {
        width: 100%;

        &Large {
          width: 100%;
        }
      }
    }
  }

  .pagination {
    margin-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .sort {
    margin-top: 15px;
    width: 100%;
  }

  .sum {
    width: 100%;
    justify-content: space-between;
  }
  .check {
    margin-left: 0px;
  }
  .cards {
    display: flex;
    justify-content: center;
    margin: 11px auto;
    flex-wrap: wrap;
    gap: 16px;
    height: 307px;
    overflow-y: auto;

    &Small {
      margin-top: 1px !important;
      padding-top: 16px !important;
      height: 307px;
      overflow-y: auto;
      &NotFound {
        min-height: 327px !important;
        border-bottom: 0px !important;
      }
    }

    &Height {
      &LargeModal {
        min-height: 307px;
      }

      &SmallModal {
        min-height: 307px;
        border-bottom: 1px solid #C7C7C7;
      }
    }
    &NotFound {
      min-height: auto;
      justify-content: center;
      padding-top: 0px;
    }
  }

  .cardListModalContentInput {
    font-size: 14px;
  }
}
