.pagination {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.pc {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.mobile {
  display: none;
}
.nextBtn {
  padding-left: 10px;
  text-align: left;
  background-image: url('/src/assets/icons/arrow-right.svg');
  background-position: 90% 50%;
  &Mobile {
    width: 50px !important;
    background-position: center;
    &:hover,&:disabled {
       background-position: center !important;
    }
  }
  &:hover {
    background-color: #000;
    color: #fff;
    background-image: url('/src/assets/icons/arrow-right-hover.svg');
    background-position: 88% 50%;
    background-repeat: no-repeat;
  }
  &:disabled {
    color: #fff;
    background-color: #e5e5e5;
    background-image: url('/src/assets/icons/arrow-right-hover.svg');
    border: solid 2px #e5e5e5;
  }
}
.prevBtn {
  padding-right: 10px;
  text-align: right;
  background-image: url('/src/assets/icons/arrow-left.svg');
  background-position: 10% 50%;
  &Mobile {
     width: 50px !important;
     background-position: center;
     &:hover,&:disabled {
        background-position: center !important;
     }
  }
  &:hover {
    background-color: #000;
    color: #fff;
    background-image: url('/src/assets/icons/arrow-left-hover.svg');
    background-position: 10% 50%;
    background-repeat: no-repeat;
  }
  &:disabled {
    color: #fff;
    background-color: #e5e5e5;
    background-image: url('/src/assets/icons/arrow-left-hover.svg');
    border: solid 2px #e5e5e5;
  }
}
.button {
  width: 91px;
  height: 30px;
  font-size: 18px;
  border-radius: 15px;
  color: #000;
  background-color: #fff;
  border: solid 2px #000;
}
.text {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.page {
  margin: 0 24px;
  font-size: 18px;
}

.input {
  margin: 0 12px;
  width: 55px;
  height: 29px;
  text-align: center;
  /* border-radius: 4px; */
  border-bottom: solid 1px #000;
  color: #000;
  background-color: var(--color-background-tertiary);
}

@media (--view-md) {
  .pc {
    display: none;
  }
  .mobile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    &PageBar {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
  .page {
    text-align: center;
  }
}
