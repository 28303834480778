.base {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.transfer svg {
  width: 24px;
  height: 24px;
  fill: none;
}
.transfer {
  padding: 15px 24px;
  margin: 0 auto;
  &Migrate {
    background-color: #83d300 !important;
    &Btn {
      height: 30px;
      line-height: 30px;
      font-size: 18px;
      border: 2px solid #000000;
      text-align: center;
      border-radius: 15px;
      padding: 0px 10px !important;
      width: auto !important;
      margin-bottom: 0px !important;
      background-color: #83d300 !important;
      color: #000000 !important;
    }
  }
  &Inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #eeeeee;
    border-radius: 11px;
    padding: 20px 20px;
  }
  &Menu {
    min-width: 345px;
    text-align: right;
  }
  &Btn {
    height: 30px;
    line-height: 30px;
    border-radius: 15px;
    padding: 0px 10px !important;
    width: auto !important;
    margin-bottom: 0px !important;
    background-color: #eeeeee !important;
    color: #ff6c2d !important;
    border: 1px solid #ff6c2d;
  }
  &Info {
    display: flex;
    align-items: center;
    &Icon {
      display: flex;
      align-items: center;
    }
    &Block {
      text-align: left;
      margin-left: 15px;
      &Title {
        font-weight: 600;
        font-size: 18px;
      }
      &Msg {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}

.steps {
  display: block;
}

.step {
  margin-top: 24px;
  svg {
    fill: none;
    width: 14px;
    height: 14px;
  }
  &Title {
    display: flex;
    align-items: center;
    font-size: 18px;
    &Text {
      margin-left: 5px;
      font-weight: 600;
    }
  }
  &Content {
    padding: 5px;
    color: #909090;
  }
  &Success {
    padding: 5px;
    font-size: 14px;
    color: #ff6c2d;
  }
}

.modalNotice {
  margin-top: 24px;
  padding-top: 24px;
  padding-bottom: 24px;
  border-top: 1px solid #C7C7C7;
  &Title {
     font-weight: 600;
     font-size: 14px;
     line-height: 15px;
     color: #909090;
     margin-bottom: 8px;
  }
  &Msg {
     font-size: 12px;
     line-height: 13px;
     color: #909090;
  }
}

.cardList {
  &Modal {
    width: 968px;
    height: 630px;
    &Content {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      &Pagination {
        margin-top: 0px;
        padding-top: 10px;
      }
      &Btn {
        text-align: center;
        margin-top: 18px;
      }
    }
  }
}
.migrateSuccess div {
  padding: 15px 0px;
}

@media (max-width: 968px) {
  .transfer {
    flex-direction: column;
    padding: 16px 16px;
    &Migrate {
      &Btn {
        font-size: 14px !important;
      }
    }
    &Btn {
      font-size: 15px !important;
    }
    &Menu {
      text-align: left;
    }
    &Info {
      &Block {
        margin-left: 0px;
        margin-bottom: 20px;
      }
      &Icon {
        display: none;
      }
    }
    &Inner {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .cardList {
    &Modal {
       width: 80%;
       min-width: 370px;
       height: 664px;
      &Content {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        &Pagination {
          margin-top: 36px;
        }
        &Btn {
          text-align: left;
          margin-top: 30px;
        }
      }
    }
  }
}
@media (width: 320px) {
  .transfer {
    &Btn {
       font-size: 15px !important;
       width: 250px !important;
       margin-bottom: 0px !important;
       padding: 0px !important;
       background-color: #eeeeee !important;
       color: #ff6c2d !important;
       border: 1px solid #ff6c2d;
     }
  }
}
