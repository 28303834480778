@import 'ress';
@import 'root.css';
@import 'media.css';
@import 'tippy.js/dist/tippy.css';
@import url('https://fonts.googleapis.com/css?family=Archivo:300,400,500,600,700&display=swap');
html,
body {
  height: 100%;
  color: var(--color-text);
  font-size: 16px;
  line-height: 1.5;
  font-family: 'Archivo', 'PingFang SC', 'Microsoft YaHei', 'Helvetica Neue',
    'Hiragino Sans GB', 'WenQuanYi Micro Hei', Arial, sans-serif;
  background-color: #ffffff;
  font-weight: 400;
}

#root {
  height: 100%;
}

a {
  color: #000;
  text-decoration: none;

  &:hover,
  &:focus {
    color: var(--color-highlight);
  }
}

@font-face {
  font-family: 'Chivo';
  font-display: swap;
  font-weight: 100 900;
  src: local('Chivo'),
    url(assets/fonts/chivo/Chivo-VariableFont.ttf) format('truetype-variations');
}

@font-face {
  font-family: 'Marcellus';
  src: local('Marcellus'),
    url(assets/fonts/marcellus/Marcellus-Regular.ttf) format('truetype');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
