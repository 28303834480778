:root {
  --color-highlight: #c39175;
  --color-light-primary: #ecdec8;
  --color-light-secondary: #70512e;
  /* --color-background-tertiary: #4d392f;*/
  --color-background-secondary: #574134;
  --color-background-primary: #2f231d;
  --color-light: #fff;
  --color-dark: #000;
  --color-primary: #ff6c2d;
  --color-secondary: #3864ff;
  --color-text: #262626;
  --color-disable: #909090;
  --color-grey: #f5f5f5;
  --color-link: #c2ad3a;
  --color-border: #909090;
  --color-warning: #d65a55;
  --color-background-light: #eee;
  --color-mask-bg: rgba(0, 0, 0, 0.6);
  --z-index-modal: 1400;
  --z-index-message: 1500;
  --z-index-header: 1300;
  --z-index-mask: 1500;
  --color-button: #000000;
  --color-button-background: #ffffff;
  --font-size-xs: 0.75rem; /* 12px */
  --font-size-sm: 0.875rem; /* 14px */
  --font-size-md: 1rem; /* 16px */
  --font-size-lg: 1.125rem; /* 18px */
  --font-size-xl: 1.25rem; /* 20px */
  --font-size-2xl: 1.5rem; /* 24px */
  --font-size-3xl: 2.5rem; /* 40px */
}
.button {
  display: inline-flex;
  align-items: center;
  width: 91px;
  height: 30px;
  font-size: 18px;
  border-radius: 15px;
  color: #000;
  background-color: #fff;
  border: solid 2px #000;
}
.prevBtn {
  padding-right: 10px;
  justify-content: right;
  background-image: url('/src/assets/icons/arrow-left.svg');
  background-position: 10% 50%;
  &:hover {
    background-color: #000;
    color: #fff;
    background-image: url('/src/assets/icons/arrow-left-hover.svg');
    background-position: 10% 50%;
    background-repeat: no-repeat;
  }
  &:disabled {
    color: #fff;
    background-color: #e5e5e5;
    background-image: url('/src/assets/icons/arrow-left-hover.svg');
    border: solid 2px #e5e5e5;
  }
}
.backBtn {
  text-transform: uppercase;
  width: 212px;
  height: 40px;
  background: #3864ff !important;
  border-radius: 15px;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #ffffff !important;
  cursor: pointer;
}
.buy,
.trade {
  height: 40px;
  line-height: 40px;
  width: 212px;
  padding: 0px;
  text-align: center;
  border-radius: 15px;
  margin-bottom: 10px;
  font-size: 18px;
  border: 2px solid #ff6c2d;
  color: #fff !important;
  background-color: #ff6c2d !important;
  &:hover {
    border: 2px solid #ff6c2d;
    color: #fff !important;
    background-color: #ff6c2d !important;
  }
  &:focus {
  }
}
.tradeDisabled {
  height: 40px;
  width: 212px;
  padding: 0px;
  border-radius: 15px;
  margin-bottom: 10px;
  font-size: 18px;
  border: 2px solid #909090;
  color: #909090 !important;
  background-color: #fff !important;
}
input {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
}

.button-normal {
  height: 30px;
  line-height: 30px;
  border-radius: 15px;
  padding: 0px 10px !important;
  background-color: #ffffff !important;
  color: #ff6c2d !important;
  border: 1px solid #ff6c2d;
  cursor: pointer;
}
.fullWidth {
  width: 100% !important;
}
