.login {
  height: 100%;
}

.inner {
  max-width: 1200px;
  width: 100%;
  margin: 120px auto 0 auto;
}

.title {
  text-align: center;
}

.metamask {
  width: 96px;
  height: 96px;

  &Wrapper {
    margin: 68px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.connect {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
