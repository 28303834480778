.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 4px 0;
  border-radius: 16px;
  font-size: var(--font-size-lg);

  .icon {
    width: 16px;
    height: 16px;
  }

  &.normal {
    border: 2px solid var(--color-dark);
    color: var(--color-dark);
    background-color: var(--color-light);

    &:hover,
    &:focus {
      color: var(--color-light);
      background-color: var(--color-dark);
    }
  }

  &.primary {
    background-color: var(--color-primary);
    color: var(--color-light);
  }

  &.secondary {
    background-color: var(--color-secondary);
    color: var(--color-light);
  }

  &.info {
    border: 2px solid var(--color-primary);
    border-radius: 16px;
    color: var(--color-primary);
  }

  &:disabled {
    cursor: not-allowed;
    border: 2px solid var(--color-disable);
    color: var(--color-disable);
    background-color: var(--color-light);
  }
}
