.button {
  width: 212px;
}

.modal {
  width: 368px;
}

.cancel {
  width: 96px;
}

.buy {
  display: block;

  &Content {
    margin: 24px 0px;
    border-radius: 4px;
    border-top: none;
  }

  &Item {
    padding: 16px 0px;
    display: flex;
    justify-content: space-between;
  }
  &Item:first-child {
    border-bottom: 1px solid #000000;
  }

  &Name {
    display: flex;
    flex: 1;
    align-items: center;
    margin-bottom: 8px;
    &Total {
      font-weight: 600;
      font-size: 14px;
      color: #909090;
    }
  }
  &Card {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    border-radius: 4px;
    background-color: #eeeeee;

    &Info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1 1;
      align-items: center;
      &Left {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
    }
  }
  &Race {
    margin: 0 8px;
    margin: 0 8px;
    display: flex;
    align-items: center;
    svg {
      width: 14px;
      height: 14px;
    }
  }
  &Id {
    align-items: center;
    display: flex;
    margin: 0 8px;
  }

  &Price {
    display: flex;
    width: 100%;
    justify-content: space-between;
    &Flex {
      display: flex;
      align-items: center;
    }
    &ETH {
      text-align: right;
      font-size: 14px;
      color: #000000;
      &Highlight {
        text-align: right;
        font-size: 18px;
        font-weight: 600;
        color: #ff6c2d;
      }
    }

    &USD {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #ff6c2d;
      border: 1px solid #ff6c2d;
      border-radius: 12px;
      height: 13px;
      margin-left: 10px;
      padding: 7px 5px;
      &Normal {
        font-size: 14px;
        color: #ff6c2d;
        border: 0px;
      }
    }
  }

  &Footer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &Button {
    margin: 0 16px;
    font-size: 14px;
  }
}

.connectWalet {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &Text {
    margin: 24px 0;
    text-align: center;
  }
}
