.info {
  width: 468px;
  margin: 0 auto;
  padding: 0 8px;

  &Owner {
    display: flex;
    align-items: center;
    &Text {
      font-size: 18px;
    }
    svg {
      fill: none;
      width: 14px;
      height: 14px;
      margin-left: 8px;
    }
    &Address {
      display: flex;
      align-items: center;
      font-size: 14px;
      cursor: pointer;
      text-decoration: underline;
      word-break: break-all;

      &:hover {
        color: #ff6c2d;
      }
    }
  }

  &Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &Desc {
    font-size: 18px;
    font-weight: 600;
  }

  &Id {
    color: var(--color-highlight);
  }

  &Caption {
    margin: 8px 0px;
    font-size: 18px;
    border-bottom: 0.5px solid #909090;
    font-weight: normal;
  }
  &Card {
    margin-bottom: 48px;
    &Top {
      display: flex;
      &Row {
        margin-right: 38px;
      }
    }
  }
  &CardStates {
    margin-bottom: 48px;
    display: flex;
    &Text {
      display: flex;
      flex-direction: column;
      font-size: 18px;
      width: 33%;
      &Value {
        display: flex;
        align-items: center;
        margin-top: 5px;
      }
    }
  }
  &CardBodyParts {
    margin-bottom: 22px;
    display: flex;
    &Text {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      width: 50%;
      &Value {
        display: flex;
        align-items: center;
        margin-top: 5px;
      }
      &Value span {
        margin-left: 5px;
      }
    }
  }

  &Subject {
    font-size: 14px;
    margin-top: 12px;
    color: #909090;
  }

  &Text {
    font-size: 18px;
    display: flex;
    align-items: center;
  }
}
.infoCardStatesText svg,
.infoCardBodyPartsText svg {
  fill: none;
  width: 28px;
  height: 28px;
}
.infoText svg {
  fill: none;
  width: 28px;
  height: 28px;
  margin-right: 11px;
}
.infoOwnerAddress svg {
  fill: none;
  cursor: pointer;
  width: 12px;
  height: 12px;
}

@media (--view-md) {
  .info {
    width: 100%;

    &Header {
      display: none;
    }

    &Card {
      &Top {
        flex-wrap: wrap;
        &Row {
          width: 50%;
        }
      }
    }
  }
}
