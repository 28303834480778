.pricePoint {
  position: absolute;
  right: -11px;
  top: -7px;
  color: #ff6c2d;
}

.priceView {
  position: relative;
}

.smallCard {
  width: 134px !important;
  height: 165px !important;

  &Info {
    width: 142px !important;

    &Checked {
      padding-left: 4px;
      min-height: 60px !important;
    }

    &UnChecked {
      padding-left: 4px;
      min-height: 60px !important;
    }
  }

  /*
  width: 134px !important;
  height: 165px !important;
  &Info {
     width: 134px !important;
  }
  */
}

.card {
  cursor: pointer;
  position: relative;
  display: inline-flex;
  width: 212px;
  flex-direction: column;
  justify-content: center;
  height: 262px;
  background: #F6F8FD;
  border-radius: 8px;

  &Item {
    a:hover {
      color: #000000;
    }

    &Checked {
      height: 234px;
      width: 142px;
      background: #F4F4F4;
      border-radius: 11px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 4px;
    }

    &UnChecked {
      height: 234px;
      width: 142px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 4px;
    }
  }

  &Info {
    display: flex;
    margin-top: 12px;
    font-size: 12px;
    align-items: flex-start;
    flex-direction: column;
    min-height: 50px;

    span:only-child {
      margin: 0;
    }
  }

  &Id {
    display: flex;
    align-items: center;
    margin-right: auto;
    padding: 3px 6px;
    padding-left: 0;
    border-radius: 2px;
    font-size: 14px;
    height: 14px;
    line-height: 14px;

    svg {
      width: 14px;
      height: 14px;
      margin-right: 4px;
    }

    /*background-color: var(--color-background-secondary);*/
  }

  &Check {
    position: absolute;
    left: 12px;
    top: 8px;

    svg {
      fill: none !important;
    }
  }

  &Sale {
    font-size: 12px;
    right: 12px;
    top: 8px;
    height: 16px;
    line-height: 14px;
    padding: 0px 3px;
    border-radius: 12px;
    border: 1px solid #000;
    position: absolute;
  }

  &Link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }

  &Image {
  }

  &LastPrice {
    position: relative;
    font-size: 12px;
    display: flex;
    align-items: center;
    color: #909090;

    &USD {
      border: 0px;
    }
  }

  &Price {
    display: flex;
    justify-content: center;
    flex-direction: column;

    &Inner {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 212px;
      &Small {
         display: flex;
         align-items: center;
         flex-wrap: wrap;
         width: 212px;
         width: 134px;
      }
    }

    &ETH {
      font-size: 18px;
      line-height: 24px;
      margin-right: 4.5px;
    }

    &USD {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 14px;
      color: #ff6c2d;
      border: 1px solid #ff6c2d;
      border-radius: 12px;
      height: 13px;
      padding: 7px 5px;
    }
  }
}

@media (max-width: 968px) {
  .card {
    width: 160px;

    &PriceETH {
    }

    &PriceInner {
      width: 152px;
    }

    &Info {
      min-height: 38px;
    }
  }
}

@media (max-width: 320px) {
  .card {
    width: 134px;
  }
}
