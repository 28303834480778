.history {
  width: 448px;
  margin: 0 auto;
}

.header {
  margin: 32px 0px 8px 0;
  font-size: var(--font-size-lg);
  border-bottom: 0.5px solid var(--color-border);
  font-weight: normal;
}

.event {
  font-size: var(--font-size-xs);
  font-weight: 600;
}

.activity {
  display: block;
}

@media (--view-md) {
  .history {
    width: 100%;
    overflow: auto;
  }
}
