
.searchInput {
  --color-highlight: none;
  --color-light-primary: none;
  --color-light-secondary: none;
  --color-background-tertiary: none;
  --color-background-secondary: none;
  --color-background-primary: none;
  color: #000;
  padding: 6px 24px 6px 0;
  border-bottom: solid 1px #000;
  font-size: 14px;
  &Wrapper {
     position: relative;
     border-bottom: 1px solid #000000;
     display: flex;
     height: 24px;
     justify-content: center;
     align-items: center;
     padding-bottom: 5px;
    svg {
      fill: none !important;
      width: 14px !important;
      height: 14px !important;
      position: absolute;
      left: 0px;
    }
    .input {
      font-weight: 400;
      font-size: 14px;
      line-height: 15px;
      color: #909090;
      padding-left: 20px;
    }
    .input::-webkit-search-cancel-button {
      -webkit-appearance: none;
      height: 16px;
      width: 16px;
      background: url(https://yxs-web.oss-cn-beijing.aliyuncs.com/328e4d97f9d0d68ea04e872f68e508e3.png) no-repeat;
      background-size: contain;
    }
    .close {
      cursor: pointer;
    }
  }
}

