.account {
  display: flex;
  align-items: center;
  gap: 24px;
  font-family: 'Chivo';

  &Info {
    display: flex;
    flex-direction: column;
  }

  &Balance {
    display: flex;
    font-size: var(--font-size-sm);
    font-weight: 600;
  }

  &View {
    display: flex;
  }

  &Link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    font-size: var(--font-size-xs);
    border-radius: 8px;
    padding: 8px 16px;
    background-color: var(--color-primary);
    color: var(--color-dark);
    transition: transform 0.3s ease;

    &:hover,
    &:focus {
      transform: scale(1.1);
      color: var(--color-dark);
    }

    &:active {
      transform: scale(0.9);
    }
  }
}

.address {
  font-size: var(--font-size-xs);
  cursor: pointer;
}

@media (--view-md) {
  .account {
    width: 100%;
    flex-direction: column;

    &Link {
      width: 100%;

      &:hover,
      &:focus {
        transform: none;
      }

      &:active {
        transform: none;
      }
    }
  }
}
