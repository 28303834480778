.modal {
  width: auto;
  border-radius: 8px;
  background-color: var(--color-light);

  .inner {
    display: flex;
    flex-direction: column;
    padding: 24px 24px;
  }

  .header {
    position: relative;
    display: flex;
    align-items: center;
  }

  .title {
    width: 90%;
    font-size: var(--font-size-lg);
    text-align: left;
  }

  .close {
    position: absolute;
    display: inline-flex;
    top: 0;
    right: 0;
  }

  .icon {
    width: 24px;
    height: 24px;
  }
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-mask-bg);
  z-index: var(--z-index-modal);

  &.mask-enter {
    opacity: 0;

    .modal {
      opacity: 0;
      transform: scale(0.9);
    }
  }

  &.mask-enter-active {
    opacity: 1;
    transition: opacity 0.3s;

    .modal {
      opacity: 1;
      transform: scale(1);
      transition: opacity 0.3s, transform 0.3s;
    }
  }

  &.mask-exit {
    opacity: 1;

    .modal {
      opacity: 1;
      transform: scale(1);
    }
  }

  &.mask-exit-active {
    opacity: 0;
    transition: opacity 0.3s;

    .modal {
      opacity: 0;
      transform: scale(0.9);
      transition: opacity 0.3s, transform 0.3s;
    }
  }
}
