.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  padding: 8px 36px;
  border-radius: 4px;
  font-size: 18px;

  &.normal {
    border: 2px solid var(--color-light-primary);

    &:hover,
    &:focus {
      border: 2px solid var(--color-highlight);
      color: var(--color-highlight);
    }
  }

  &.primary {
    color: var(--color-background-primary);
    background-color: var(--color-light-primary);

    &:hover,
    &:focus {
      color: var(--color-background-primary);
      background-color: var(--color-highlight);
    }
  }

  &:disabled {
    cursor: not-allowed;
    background-color: var(--color-disable);

    &:hover,
    &:focus {
      background-color: var(--color-disable);
    }
  }
}
