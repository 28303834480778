
.accountInfo a:hover {
  color: #ff6c2d !important;
}
.select {
  &Option {
    display: flex;
    align-items: center;
    svg {
      width: 16px !important;
      height: 16px !important;
      margin-right: 4px;
    }
  }
}
.pc {
  display: flex;
  justify-content: center;
  align-items: center;
  &ETH {
    display: flex;
    align-items: center;
    height: 70px;
    margin-right: 20px;
    ul {
      display: flex !important;
      flex-direction: column;
      margin-left: 100px;
      list-style: none;
      width: 150px;
      height: 24px;
      line-height: 23px;
      border-bottom: 1px solid #000000;
      overflow: hidden;
      cursor: pointer;
      background-color: red;
      background: url('https://raw.githubusercontent.com/ourjs/static/gh-pages/2015/arrow.png')
        no-repeat scroll right center transparent;
      li {
        display: flex;
        align-items: center;
        /*在选择框的最右侧中间显示小箭头图片*/
        svg {
          width: 16px;
          height: 16px;
          margin-right: 4px;
        }
      }
    }
    svg {
      width: 102px;
      height: 26px;
    }
  }
}
.mobile {
  display: none;
}
.address svg {
  position: relative;
  width: 12px;
  height: 12px;
  fill: none;
}
.account {
  display: flex;
  align-items: center;
  height: 100%;
  &Balance {
    float: left;
    position: relative;
    height: 14px;
    line-height: 14px;
  }
  &Address {
    font-size: 12px;
    cursor: pointer;
  }
  &Info {
    display: block;
    font-size: 12px;
    margin-right: 33px;
    position: relative;
    &Address {
      height: 12px;
      line-height: 12px;
      float: left;
      clear: both;
    }
  }
  &Balance svg {
    position: absolute;
    top: 0;
    right: -8px;
    width: 6px;
    height: 6px;
  }
  &Text {
    height: 40px;
    font-family: 'Archivo';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #000000;
    border: 2px solid #000;
    border-radius: 15px;
    justify-content: center;
    padding: 0px 22px;
    &:hover {
      color: #fff !important;
      background-color: #000;
      border: 2px solid #000;
    }
    svg {
      width: 32px;
      height: 32px;
      fill: none;
    }
  }

  &Active {
    &:visited {
      color: #000;
    }
  }
}

.balance {
  font-size: 14px;
  font-weight: 600;
}
.address {
  position: absolute;
  right: -12px;
  top: 12px;
  font-size: 12px;
  cursor: pointer;
}

@media (max-width: 1070px) {
  .pcETH {
    display: none;
  }
}
@media (max-width: 850px) {
  .pc {
    display: none;
  }
  .address {
    position: relative;
    right: 0;
    top: 0;
  }
  .accountText {
    font-size: 12px;
    margin-top: 28px;
    width: 166px;
  }
  .account {
    width: 100%;
    justify-content: space-between;
    margin: 15px 0px;
    flex-direction: column;
    &Info {
      display: flex;
      position: relative;
      align-items: center;
      flex-direction: column;
      margin-right: 0px;
      margin-top: 20px;
    }
    &Link {
       margin-bottom: 50px;
    }
    &View {
      display: flex;
    }
  }
  .mobile {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    &Menu {
       display: flex;
       width: 100%;
       align-items: center;
       justify-content: center;
       flex-direction: column;
       font-size: 20px;
       font-weight: 400;
       padding-top: 20px;
       padding-bottom: 56px;
       border-bottom: 0.5px solid #909090;
       margin-bottom: 36px;
      &Item:not(:last-child) {
         margin-bottom: 48px;
      }
      &Item {
         position: relative;
         font-size: 20px;
         line-height: 22px;
         text-align: center;
         color: #000000;
         &Active {
           &::before {
              width: 12px;
              height: 12px;
              background: #ff6c2d;
              border-radius: 15px;
              content: '';
              position: absolute;
              left: -17px;
              top: 10px;
              -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
            }
           &::after {
              width: 100%;
              background: none;
           }
         }
         &Tip {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -6px;
            right: -28px;
            width: 35px;
            height: 14px;
            color: #FFFFFF;
            font-weight: 500;
            font-size: 12px;
            line-height: 10px;
            background: #83D300;
            border-radius: 10px;
            padding: 2px 4px;
            span {
              display: inline-block;
              transform: scale(0.75);
            }
         }
      }
    }
  }
}
@media (max-width: 960px) {
  .pc {
    &ETH {
      display: none;
    }
  }
}
@media (max-width: 320px) {
  .pc {
    display: none;
  }
  .accountText {
    font-size: 12px;
    margin-top: 28px;
  }
  .mobile {
    display: flex;
    flex-direction: column;
  }
}
