.wallet {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  font-size: var(--font-size-xs);
  font-weight: 500;
  border-radius: 8px;
  padding: 8px 16px;
  background-color: var(--color-primary);
  color: var(--color-dark);
  transition: transform 0.3s ease;

  &:hover,
  &:focus {
    transform: scale(1.1);
    color: var(--color-dark);
  }

  &:active {
    transform: scale(0.9);
  }
}

.modal {
  width: 368px;

  &Inner {
    margin: 16px 0;
    display: flex;
    flex-direction: column;
    font-size: var(--font-size-sm);
  }

  &Icon {
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }

  &Button {
    margin: 8px 0;
  }
}

@media (--view-md) {
  .wallet {
    width: 100%;

    &:hover,
    &:focus {
      transform: none;
    }

    &:active {
      transform: none;
    }
  }
}
